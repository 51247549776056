:root {
  --serif: 'Sabon';
  --sans: 'Calibre', 'Helvetica', sans-serif;
  --black: #18191a;
}


html {
  font-size: 1.2vw;
}

.title-word {
  opacity: 0;
  // transition: 0.05s ease-in-out opacity;
  &.shown {
    opacity: 1;
  }
}

body {
  // letter-spacing: 0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4;
  font-family: var(--serif);
  // hyphens:auto;
  text-align: justify;
}

p {
  text-indent: 2.5rem;
}

.ofc {object-fit: cover;}

.ss-intro.swiper-container {
  margin-left: auto;
  margin-right: inherit;
  height: 17rem;
  filter: saturate(0%) contrast(1.2);

  & .swiper-slide {
    height: 17rem;
  }
}

.ss-old {
  height: 17rem;
}

.mw75{max-width: 75rem;}
.mxa{margin-left: auto;margin-right: auto;}
.mla {margin-left: auto;}

.ff-sans{font-family: var(--sans);}


.bgc-b{background-color: var(--black);}
.cw{color: #fff}

.c {top: 50%; left: 50%;
transform: translate(-50%, -50%);}
.cx {left: 50%; transform: translateX(-50%)}

.end-text {
  column-count: 2;
}

.end-img-1 {
  // margin-right: 100px;
  width: 20rem;
  float: left;
  padding: 2rem;
  margin-left: -11rem;
}

.end-img-2 {
  // margin-right: 100px;
  width: 20rem;
  float: right;
  padding: 2rem;
  margin-right: -10rem;
}

.sub {width: 50%;}

.sub-fl:before, .sub-fr:before {
  content: '';
  width: 12.5rem;
  height: 12.5rem;
  clear:left;
}

.sub-fl:before {
  float: right;
}

.sub-fr:before {
  float: left;
}


@media screen and (max-width: 800px) {
  html {font-size: 4vw;}

  .ss-intro.swiper-container {
    margin-left: auto;
    margin-right: auto;
    height: 12rem;

    & .swiper-slide {
      height: 12rem;
    }
  }

  .end-img-1 {
    // margin-right: 100px;
    width: 100%;
    float: none;
    padding: 2rem 0;
    margin-left: 0;
  }

  .end-text {
    column-count: 1;
  }
}

@media screen and (max-width: 650px) {
  html {font-size: 4vw;}
  body {text-align: left;}
}